import React, { memo } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Hero, About, Jobs, Featured, Projects, Contact } from '@components';

const IndexPage = ({ data }) => (
  <div id="content" className="l-content">
    <Hero data={data.hero.edges} />
    <About data={data.about.edges} />
    <Jobs data={data.jobs.edges} />
    <Featured data={data.featured.edges} />
    <Projects data={data.projects.edges} />
    <Contact data={data.contact.edges} />
  </div>
);

IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default memo(IndexPage);

export const pageQuery = graphql`
  {
    hero: allMdx(filter: { fileAbsolutePath: { regex: "//hero//" } }) {
      edges {
        node {
          ...Hero
        }
      }
    }
    about: allMdx(filter: { fileAbsolutePath: { regex: "//about//" } }) {
      edges {
        node {
          ...AboutItem
        }
      }
    }
    jobs: allMdx(
      filter: { fileAbsolutePath: { regex: "//jobs//" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          ...JobItem
        }
      }
    }
    featured: allMdx(
      filter: {
        fileAbsolutePath: { regex: "//featured//" }
        frontmatter: { show: { eq: "true" } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          ...FeaturedItem
        }
      }
    }
    projects: allMdx(
      filter: {
        fileAbsolutePath: { regex: "//projects//" }
        frontmatter: { show: { eq: "true" } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          ...ProjectItem
        }
      }
    }
    contact: allMdx(filter: { fileAbsolutePath: { regex: "//contact//" } }) {
      edges {
        node {
          ...ContactItem
        }
      }
    }
  }
`;
